<template>
<div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
       <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>运营管理</el-breadcrumb-item>
       <el-breadcrumb-item>广告管理</el-breadcrumb-item>
      <el-breadcrumb-item>广告位置</el-breadcrumb-item>
      </el-breadcrumb>
    <el-card style=" height: 780px; overflow: auto;">
      <el-row>
        <el-col :span="24">
        <div class="grid-content bg-purple-dark">
        <el-button class="el-buttonr" size="mini" type="success" @click="outerVisible = true">新增广告位</el-button>
          </div>
          </el-col>
      </el-row>
      <!-- 分类搜索 -->
      <el-row style="margin-top: 15px">
        <el-col class="mr15" :span="3.3">
          <el-input  size="mini" placeholder="广告位置"   >
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </el-col>
      </el-row>
    <!--商品数据展示区域-->
      <el-table
        :data="tableData"
        style="width: 1000px;"
        
        ref="singleTable"
        highlight-current-row
        border
        row-key="id"
        stripe >
        <el-table-column  label="序号"  width="50"/>
        <el-table-column prop="name" label="广告位置" width="250" show-overflow-tooltip/>
        <el-table-column prop="nam" label="广告位置尺寸" width="200" show-overflow-tooltip/>
        <el-table-column prop="money" label="广告位置状态" width="250" show-overflow-tooltip/>
        <el-table-column label="操作"  min-width="200" fixed="right">
          <el-button @click="dialogVisible = true" size="mini"  type="primary">编辑</el-button>
           <el-button @click="dialogVisible = true" size="mini" type="success">启用</el-button>
          <el-button @click="dialogVisible = true" size="mini" type="danger">停用</el-button>
        </el-table-column>
      </el-table>
      <!--分页-->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
     <el-dialog
      width="30%"
      title="新增商广告位置"
      :append-to-body="true"
      :visible.sync="outerVisible"
    >
   <el-form :model="form">
        <el-form-item label="广告位置" required>
          <el-col class="mr15" :span="12">
              <el-input size="mini" placeholder="首页轮播图"   ></el-input>
            </el-col>
        </el-form-item>
        <el-form-item label="广告位置尺寸" required>
          <el-col style="display: flex;" class="mr15" :span="12">
            <span>宽：</span>
              <el-input class="mr15" style=" float: left;" size="mini"  placeholder=""   ></el-input>
             <span >高：</span> 
              <el-input size="mini" placeholder=""    ></el-input>
            </el-col>
         </el-form-item>
          <el-form-item label="广告位置状态" required>
         <el-radio v-model="radio" label="1">开启</el-radio>
           <el-radio v-model="radio" label="2">停用</el-radio>
          </el-form-item>
   </el-form>
   <span slot="footer" class="dialog-footer">
         <el-button type="danger" @click="innerVisible = false" >取消</el-button>
          <el-button type="primary" @click="innerVisible = false">确定</el-button>
        </span>
    </el-dialog>
    </div>
</template>

<script>
export default {
data(){
  return{
    total:1,
    outerVisible:false,
     queryInfo: {
        pageNum: 1,
        pageSize: 10,
        time: "2021-04-23 11:18:54",
        province: "内蒙古自治区",
        city: "兴安盟",
      },
   tableData:[{
        name:"首页轮播图",
        nam:"宽：50  px        高：30  px",
        money:"停用",
        signProvince:"停用"
        }]
  }
}
}
</script>

<style>
.ui{
  width: 500px;
}
.mr15 {
  margin-right: 15px;
}
</style>